const templateTypes = {
  CLEAR_ALL_ERRORS: "CLEAR_ALL_ERRORS",
  GET_SECTION_DATA: "GET_SECTION_DATA",
  START_SECTION_LOADER: "START_SECTION_LOADER",
  CREATE_INITIATIVE: "CREATE_INITIATIVE",
  CREATE_INITIATIVE_V2: "CREATE_INITIATIVE_V2",
  SET_TEMPLATE_CATEGORY: "SET_TEMPLATE_CATEGORY",
  CREATE_INITIATIVE_V3: "CREATE_INITIATIVE_V3",
};

export { templateTypes };
