const reportFullStoryTemplateCreateEvent = (
  config = {
    categoryId: 0,
    categoryName: "",
    isSearchData: false,
    searchResultsCount: 0,
    searchTerm: "",
    templateId: 0,
    templateName: "",
  }
) => {
  if (!window.FS) {
    return;
  }
  const { categoryId, categoryName, isSearchData, templateId, templateName, searchResultsCount, searchTerm } = config;
  const payload = {
    Mode: isSearchData ? "Search" : "Browse",
    "Search Results Count": searchResultsCount || 0,
    "Search Term": searchTerm || "",
    "Template Category ID": categoryId,
    "Template Category Name": categoryName,
    "Template ID": templateId,
    "Template Name": templateName,
  };
  window.FS.event("use template btn click", payload);
};

export { reportFullStoryTemplateCreateEvent };
