import { defaultTo, defaultToEmptyObject } from "gx-npm-lib";
import { templateTypes } from "../actions/actionTypes";

export const templateReducer = (state, action) => {
  let newState;
  switch (action?.type) {
    case templateTypes.START_SECTION_LOADER:
      newState = {
        ...state,
        template: {
          ...state.template,
          [action.section]: {
            ...state.template[action.section],
            isLoading: true,
          },
        },
      };
      break;

    case templateTypes.GET_SECTION_DATA:
      newState = {
        ...state,
        template: {
          ...state.template,
          [action.section]: {
            ...state.template[action.section],
            isLoading: false,
            error: action.error ? action.error : null,
            data: action.data,
          },
        },
      };
      break;
    case templateTypes.CREATE_INITIATIVE:
      newState = { ...defaultToEmptyObject(state) };
      newState.template = { ...defaultToEmptyObject(newState.template) };
      newState.template.createInitiative = { ...defaultToEmptyObject(newState.template.createInitiative) };
      newState.template.createInitiative.data = action.data;
      newState.template.createInitiative.error = defaultTo(action.error);
      newState.template.createInitiative.isLoading = !!action.isLoading;
      break;
    case templateTypes.CREATE_INITIATIVE_V2:
      newState = { ...defaultToEmptyObject(state) };
      newState.template = { ...defaultToEmptyObject(newState.template) };
      newState.template.createInitiative = { ...defaultToEmptyObject(newState.template.createInitiative) };
      newState.template.createInitiative.error = defaultTo(action.error);
      newState.template.createInitiative.isLoading = !!action.isLoading;
      break;
    case templateTypes.SET_TEMPLATE_CATEGORY:
      newState = { ...defaultToEmptyObject(state) };
      newState.template = { ...defaultToEmptyObject(newState.template) };
      newState.template.category = action.category;
      break;
    case templateTypes.CLEAR_ALL_ERRORS:
      newState = { ...defaultToEmptyObject(state) };
      newState.template = { ...defaultToEmptyObject(newState.template) };
      newState.template.checklist = {
        ...defaultToEmptyObject(newState.template.checklist),
      };
      newState.template.checklist.error = null;
      newState.template.createInitiative = {
        ...defaultToEmptyObject(newState.template.createInitiative),
      };
      newState.template.createInitiative.error = null;
      newState.template.overview = {
        ...defaultToEmptyObject(newState.template.overview),
      };
      newState.template.overview.error = null;
      newState.template.products = {
        ...defaultToEmptyObject(newState.template.products),
      };
      newState.template.products.error = null;
      newState.template.requirements = {
        ...defaultToEmptyObject(newState.template.requirements),
      };
      newState.template.requirements.error = null;
      newState.template.scorecard = {
        ...defaultToEmptyObject(newState.template.scorecard),
      };
      newState.template.scorecard.error = null;
      break;
    default:
      newState = state;
  }
  return newState;
};
