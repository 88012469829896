import React from "react";
import { makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { CheckmarkCircledIcon, CheckmarkDottedCircledOutlineIcon } from "gx-npm-icons";
import styles from "../styles";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import PropTypes from "prop-types";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const darkPear = colorPalette.status.darkPear.hex;
const useStyles = makeStyles(() => styles);
const propTypes = {
  item: PropTypes.object,
};
const ChecklistRow = ({ item }) => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      {item?.complete ? <CheckmarkCircledIcon fillPath={darkPear} /> : <CheckmarkDottedCircledOutlineIcon />}
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            rootClassName={classes.checklistNameGCOM3606}
            styling={"p3"}
            color={"iron"}
            boldness={"medium"}
          >
            {item?.name}
          </TypographyComponent>
          <TypographyComponent
            rootClassName={classes.checklistStatus}
            styling={"p3"}
            color={"iron"}
            boldness={"regular"}
          >
            {item?.description}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <div className={classes.checklistName}>{item?.name}</div>
          <div className={classes.checklistStatus}>{item?.description}</div>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </div>
  );
};
ChecklistRow.propTypes = propTypes;
export default ChecklistRow;
