import { getAsyncRequest, postAsyncRequest } from "gx-npm-lib";

const getRequest = async (url = "") => {
  return getAsyncRequest(url);
};

const postRequest = async (url = "", payload = {}) => {
  return postAsyncRequest(url, payload);
};

export { getRequest, postRequest };
