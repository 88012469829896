import { isValidResponse } from "gx-npm-lib";

/**
 * @param {*} id id value to put after template
 * @param {*} endpoint endpoint value to put after id slash, will add empty string if null is put in with no slash
 * @param {*} apiVersion api version to use, defaults to 2
 * @returns api URL for template
 */
const getApiUrl = (id, endpoint = null, apiVersion = 2) => {
  return `/api/v${apiVersion}/data/template/preview/${id}${endpoint !== null ? `/${endpoint}` : ``}`;
};

/**
 * will return GET response data from an API, or an error if one occured, along with loading flag to be spread into state
 * @param {*} response API response
 * @param {*} storedKey name of key to store data in to be spread into app state
 * @returns object with data or error, and loading flag
 */
const processGetResponse = (response, storedKey = "") => {
  const payload = {};
  if (isValidResponse(response) && typeof response?.data?.data === "object") {
    const data = response.data.data || null;
    const key = storedKey || "data";
    payload[key] = data;
  } else {
    payload.error = { response };
  }
  return payload;
};
export { isValidResponse, processGetResponse, getApiUrl };
