import { makeStyles } from "@material-ui/core";
import React from "react";
import classNames from "classnames";
import styles from "./styles";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";
import PropTypes from "prop-types";
const useStyles = makeStyles(() => styles);
const propTypes = {
  textStyle: PropTypes.string,
  maxLine: PropTypes.number,
  text: PropTypes.string,
  hideTitle: PropTypes.bool,
};
const TruncateText = ({ textStyle, maxLine, text, hideTitle }) => {
  const classes = useStyles();
  return (
    <span
      style={{ WebkitLineClamp: maxLine || 1 }}
      title={!hideTitle && text ? text : null}
      className={classNames(classes.truncateStyle, textStyle)}
    >
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent element={"span"} styling={"p2"} boldness={"regular"} color={"coal"}>
            {text}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <span>{text}</span>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </span>
  );
};
TruncateText.propTypes = propTypes;
export default TruncateText;
