export const TEMPLATE_SECTION_OVERVIEW = "overview";
export const TEMPLATE_SECTION_OVERVIEW_INDEX = 0;
export const TEMPLATE_SECTION_REQUIREMENTS = "requirements";
export const TEMPLATE_SECTION_REQUIREMENTS_INDEX = 1;
export const TEMPLATE_SECTION_PRODUCTS = "products";
export const TEMPLATE_SECTION_PRODUCTS_INDEX = 2;
export const TEMPLATE_SECTION_CHECKLIST = "checklist";
export const TEMPLATE_SECTION_CHECKLIST_INDEX = 3;
export const TEMPLATE_SECTION_EVALUATION = "evaluation";
export const TEMPLATE_SECTION_EVALUATION_INDEX = 4;
export const NAV_INITIATIVE = "/s/evaluation/";
/**
 * @deprecated
 */
export const NAV_INITIATIVE_OVERVIEW = "/s/initiatives/?tab=overview&id=";
/**
 * @deprecated
 */
export const NAV_MARKET_OVERVIEW = "/s/market-profile/";
export const NAV_MARKET = "/s/market/";
/**
 * @deprecated
 */
export const NAV_TEMPLATE_PREVIEW_PRODUCT = "/s/template-preview-product/";
export const REQUIREMENTS_PRIORITY_MAP = Object.freeze({
  3: "High",
  2: "Medium",
  1: "Low",
});
