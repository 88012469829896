import React, { Fragment } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import TeamScore from "../../../assets/images/teamScore.svg";
import VendorQuestionnaire from "../../../assets/images/vendorQuestionnaire.svg";
import styles from "./styles";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

const propTypes = { selectedMenu: PropType.bool };
const useStyle = makeStyles(() => styles);
const EvaluationActivities = ({ selectedMenu = false }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  return (
    <Fragment>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            rootClassName={selectedMenu && "activeHeader"}
            styling={"h4"}
            boldness={"medium"}
            color={"carbon"}
          >
            {t("Evaluation activities")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <h4 className={classNames(classes.header, selectedMenu && "activeHeader")}>{t("Evaluation activities")}</h4>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>

      <Fragment>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <div className={classes.container}>
              <div className={classes.leftContainer}>
                <TypographyComponent
                  rootClassName={classes.contentHeaderGCOM3606}
                  color={"carbon"}
                  styling={"p2"}
                  boldness={"medium"}
                >
                  {t("Vendor questionnaires")}
                </TypographyComponent>
                <TypographyComponent
                  rootClassName={classes.contentTextGCOM3606}
                  color={"iron"}
                  styling={"p3"}
                  boldness={"regular"}
                >
                  {t(
                    "Request information from vendors and view their responses to evaluate surveyed vendors inside the application."
                  )}
                </TypographyComponent>
                <img src={VendorQuestionnaire} alt="vendor questionnaire svg" />
              </div>
              <div className={classes.rightContainer}>
                <TypographyComponent
                  rootClassName={classes.contentHeaderGCOM3606}
                  color={"carbon"}
                  styling={"p2"}
                  boldness={"medium"}
                >
                  {t("Score with your team")}
                </TypographyComponent>
                <TypographyComponent
                  rootClassName={classes.contentTextGCOM3606}
                  color={"iron"}
                  styling={"p3"}
                  boldness={"regular"}
                >
                  {t(
                    "Manage your evaluation with a custom scorecard built from your requirements. Collaborate with your team to compare and score each vendor."
                  )}
                </TypographyComponent>
                <img src={TeamScore} alt="teamScore svg" />
              </div>
            </div>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <div className={classes.container}>
              <div className={classes.leftContainer}>
                <TypographyComponent rootClassName={classes.contentHeader} type="p2" boldness="semi">
                  {t("Vendor questionnaires")}
                </TypographyComponent>
                <TypographyComponent rootClassName={classes.contentText} type="p3" boldness="regular">
                  {t(
                    "Request information from vendors and view their responses to evaluate surveyed vendors inside the application."
                  )}
                </TypographyComponent>
                <img src={VendorQuestionnaire} alt="vendor questionnaire svg" />
              </div>
              <div className={classes.rightContainer}>
                <TypographyComponent rootClassName={classes.contentHeader} type="p2" boldness="semi">
                  {t("Score with your team")}
                </TypographyComponent>
                <TypographyComponent rootClassName={classes.contentText} type="p3" boldness="regular">
                  {t(
                    "Manage your evaluation with a custom scorecard built from your requirements. Collaborate with your team to compare and score each vendor."
                  )}
                </TypographyComponent>
                <img src={TeamScore} alt="teamScore svg" />
              </div>
            </div>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </Fragment>
    </Fragment>
  );
};

EvaluationActivities.propTypes = propTypes;
export default EvaluationActivities;
