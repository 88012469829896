// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t856k_4A_Yg43Hfd_vc2 .gx-dialog-body{margin-left:-36px;width:600px}.VB2owsLS0s1iRX3gQBUi{padding:24px 36px 56px 36px}.MDRDqP8mQXhcwG7lgOvu{background:var(--color-interactions-light-berry);padding:56px 0 74px 36px}.MDRDqP8mQXhcwG7lgOvu .U51qc0xvL4l__eDvvLxi{display:block}.MDRDqP8mQXhcwG7lgOvu .D9YMuAuDb1K5MNBx_QDC{position:relative}.MDRDqP8mQXhcwG7lgOvu .D9YMuAuDb1K5MNBx_QDC .KYBdXimdoJYyq9_tDH9A{bottom:-74px;position:absolute;right:0}.Gg04CWB6QCF9zR1fofqh{align-items:center;display:flex;justify-content:flex-end}.Gg04CWB6QCF9zR1fofqh .GvvAgCLMRJpBJu1EoEYR{margin-right:24px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/free-trial/free-trial-init-create-modal/free-trial-init-create-modal.styles.module.scss"],"names":[],"mappings":"AACE,sCACE,iBAAA,CACA,WAAA,CAIJ,sBACE,2BAAA,CAGF,sBACE,gDAAA,CACA,wBAAA,CAEA,4CACE,aAAA,CAEF,4CACE,iBAAA,CAEA,kEACE,YAAA,CACA,iBAAA,CACA,OAAA,CAKN,sBACE,kBAAA,CACA,YAAA,CACA,wBAAA,CAEA,4CACE,iBAAA","sourcesContent":[".dialogRoot {\n  & :global(.gx-dialog-body) {\n    margin-left: -36px;\n    width: 600px;\n  }\n}\n\n.subtitleParagraph {\n  padding: 24px 36px 56px 36px\n}\n\n.remainingInitsHeading {\n  background: var(--color-interactions-light-berry);\n  padding: 56px 0 74px 36px;\n\n  & .headingSecondLine {\n    display: block;\n  }\n  & .cornerImgContainer {\n    position: relative;\n\n    & .cornerImg {\n      bottom: -74px;\n      position: absolute;\n      right: 0;\n    }\n  }\n}\n\n.footerButtonContainer {\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n\n  & .cancelButton {\n    margin-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogRoot": `t856k_4A_Yg43Hfd_vc2`,
	"subtitleParagraph": `VB2owsLS0s1iRX3gQBUi`,
	"remainingInitsHeading": `MDRDqP8mQXhcwG7lgOvu`,
	"headingSecondLine": `U51qc0xvL4l__eDvvLxi`,
	"cornerImgContainer": `D9YMuAuDb1K5MNBx_QDC`,
	"cornerImg": `KYBdXimdoJYyq9_tDH9A`,
	"footerButtonContainer": `Gg04CWB6QCF9zR1fofqh`,
	"cancelButton": `GvvAgCLMRJpBJu1EoEYR`
};
export default ___CSS_LOADER_EXPORT___;
