import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { debounce, makeStyles } from "@material-ui/core";
import Menubar from "./sidebar";
import Checklist from "./checklist";
import Requirements from "./requirements";
import TemplateOverview from "./overview";
import Products from "./products";
import styles from "./styles";
import {
  TEMPLATE_SECTION_CHECKLIST_INDEX,
  TEMPLATE_SECTION_EVALUATION_INDEX,
  TEMPLATE_SECTION_OVERVIEW_INDEX,
  TEMPLATE_SECTION_PRODUCTS_INDEX,
  TEMPLATE_SECTION_REQUIREMENTS_INDEX,
} from "../../lib/constants";
import TemplateHeader from "./header";
import EvaluationActivities from "./evaluationActivities";

let enableScrollDetection = true;
const propTypes = {
  canUseTemplate: PropTypes.bool,
  categoryName: PropTypes.string,
  categoryId: PropTypes.number,
  isSearchData: PropTypes.bool,
  searchResultsCount: PropTypes.number,
  searchTerm: PropTypes.string,
  templateId: PropTypes.number,
  templateName: PropTypes.string,
  userInitCount: PropTypes.number,
};
const useStyles = makeStyles(() => styles);
const Template = ({
  canUseTemplate = false,
  categoryName = "",
  categoryId = 0,
  isSearchData = false,
  searchResultsCount = 0,
  searchTerm = "",
  templateId = 0,
  templateName = "",
  userInitCount = 0,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [isAtTop, setIsAtTop] = useState(true);
  const [scrollTimer, setScrollTimer] = useState(0);
  const templateOverviewRef = useRef();
  const requirementsRef = useRef(null);
  const productsRef = useRef(null);
  const checklistRef = useRef(null);
  const evaluationActivitiesRef = useRef(null);
  const bodyRef = useRef();
  const menu = useMemo(
    () => [
      {
        id: TEMPLATE_SECTION_OVERVIEW_INDEX,
        menuRef: templateOverviewRef,
        text: t("Template Overview"),
      },
      {
        id: TEMPLATE_SECTION_REQUIREMENTS_INDEX,
        menuRef: requirementsRef,
        text: t("Requirements"),
      },
      {
        id: TEMPLATE_SECTION_PRODUCTS_INDEX,
        menuRef: productsRef,
        text: t("Vendors"),
      },
      {
        id: TEMPLATE_SECTION_CHECKLIST_INDEX,
        menuRef: checklistRef,
        text: t("Checklist"),
      },
      {
        id: TEMPLATE_SECTION_EVALUATION_INDEX,
        menuRef: evaluationActivitiesRef,
        text: t("Evaluation activities"),
      },
    ],
    [t]
  );

  useEffect(() => {
    const bodyReference = bodyRef?.current;
    const handleScroll = () => {
      if (bodyReference?.scrollTop === 0) {
        setIsAtTop(true);
      } else {
        setIsAtTop(false);
      }

      if (enableScrollDetection) {
        menu.forEach((item) => {
          const sectionHeight = item?.menuRef?.current?.offsetHeight;
          const sectionTop = item?.menuRef?.current?.offsetTop - 260;
          const scrollY = bodyReference?.scrollTop;
          const sectionBottom = sectionTop + sectionHeight;
          if (scrollY > sectionTop && scrollY <= sectionBottom) {
            setSelectedMenu(item?.id);
          }
        });
      }
    };
    const debouncedHandleScroll = debounce(handleScroll, 30);
    const handleScrollListener = () => debouncedHandleScroll();
    bodyReference?.addEventListener("scroll", handleScrollListener);
    return () => {
      bodyReference?.removeEventListener("scroll", handleScrollListener);
    };
  }, [menu]);

  const handleMenuChange = (selectedMenuRef, id) => {
    enableScrollDetection = false;
    const offset = selectedMenuRef?.current?.offsetTop;
    setSelectedMenu(id);
    if (bodyRef.current) {
      bodyRef.current?.scrollTo({ top: offset - 200, behavior: "smooth" });
    }
    clearTimeout(scrollTimer);
    setScrollTimer(setTimeout(() => (enableScrollDetection = true), 1000));
  };
  return (
    <Fragment>
      <div className={classNames(classes.container)}>
        <div className={classNames(classes.menu)}>
          <Menubar menu={menu} handleMenuChange={handleMenuChange} selectedMenu={selectedMenu} />
        </div>
        <div className={classes.content} aria-label={"data-container"} ref={bodyRef}>
          <div className={classNames(classes.templateContainer)}>
            <TemplateHeader
              canUseTemplate={canUseTemplate}
              categoryName={categoryName}
              categoryId={categoryId}
              isAtTop={isAtTop}
              isSearchData={isSearchData}
              searchResultsCount={searchResultsCount}
              searchTerm={searchTerm}
              templateId={templateId}
              templateName={templateName}
              userInitCount={userInitCount}
            />
          </div>
          <div className={classNames(classes.detailsContainer)}>
            <div ref={templateOverviewRef} className={classNames(classes.detailItem, classes.detailItemFirstElement)}>
              <TemplateOverview
                templateId={templateId}
                selectedMenu={selectedMenu === TEMPLATE_SECTION_OVERVIEW_INDEX}
              />
            </div>
            <div ref={requirementsRef} className={classNames(classes.detailItem)}>
              <Requirements
                templateId={templateId}
                selectedMenu={selectedMenu === TEMPLATE_SECTION_REQUIREMENTS_INDEX}
              />
            </div>
            <div ref={productsRef} className={classNames(classes.detailItem)}>
              <Products
                categoryName={categoryName}
                categoryId={categoryId}
                isSearchData={isSearchData}
                searchResultsCount={searchResultsCount}
                searchTerm={searchTerm}
                selectedMenu={selectedMenu === TEMPLATE_SECTION_PRODUCTS_INDEX}
                templateId={templateId}
              />
            </div>
            <div ref={checklistRef} className={classNames(classes.detailItem)}>
              <Checklist templateId={templateId} selectedMenu={selectedMenu === TEMPLATE_SECTION_CHECKLIST_INDEX} />
            </div>
            <div ref={evaluationActivitiesRef} className={classNames(classes.detailItem)}>
              <EvaluationActivities selectedMenu={selectedMenu === TEMPLATE_SECTION_EVALUATION_INDEX} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Template.propTypes = propTypes;
export default Template;
