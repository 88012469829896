import { colorPalette, weightPalette } from "gx-npm-common-styles";

const styles = {
  loaderContainer: {
    minHeight: "200px",
    position: "relative",
  },
  header: {
    fontWeight: weightPalette.semiBold.amount,
    fontVariationSettings: weightPalette.semiBold.variation,
    fontSize: "24px",
    lineHeight: "31px",
    letterSpacing: "0.15px",
    color: colorPalette.neutrals.carbon.hex,
  },
  content: {
    marginTop: "12px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: colorPalette.neutrals.coal.hex,
    maxWidth: "600px",
  },
  contentGCOM3606: {
    marginTop: "12px",
    maxWidth: "600px",
  },
  requirementTable: {
    marginTop: "32px",
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      zIndex: "1000",
      bottom: "0",
      left: "0",
      backgroundImage: "linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%)",
      width: "100%",
      height: "50px",
    },
  },
  countBtn: {
    marginTop: "4px",
  },
  noData: {
    paddingTop: "12px",
  },
};

export default styles;
