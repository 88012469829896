import React, { Fragment, useContext, useEffect } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  Loader,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { EvaluationStateContext } from "../../../context";
import { getTemplateData } from "../../../context/actions/templateActions";
import ErrorBanner from "../../../ui/error-banner/error-banner.component";
import RequirementRow from "./row";
import styles from "./styles";
import { TEMPLATE_SECTION_REQUIREMENTS } from "../../../lib/constants";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

const propTypes = { templateId: PropType.number, selectedMenu: PropType.bool };
const useStyles = makeStyles(() => styles);
const Requirements = ({ templateId, selectedMenu }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, dispatch] = useContext(EvaluationStateContext);
  const { isLoading, data, error } = state?.template?.requirements || {};

  useEffect(() => {
    if (templateId) {
      getTemplateData(dispatch, { templateId, section: TEMPLATE_SECTION_REQUIREMENTS }, 3);
    }
  }, [dispatch, templateId]);

  return (
    <Fragment>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            rootClassName={selectedMenu && "activeHeader"}
            styling={"h4"}
            boldness={"medium"}
            color={"carbon"}
          >
            {t("Requirements")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <h4 className={classNames(classes.header, selectedMenu && "activeHeader")}>{t("Requirements")}</h4>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      {isLoading && (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      )}
      {!isLoading && error && (
        <div className={classes.noData}>
          <ErrorBanner />
        </div>
      )}
      {!isLoading && !error && (
        <Fragment>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent
                rootClassName={classes.contentGCOM3606}
                styling={"p2"}
                boldness={"regular"}
                color={"coal"}
              >
                {data?.description}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p className={classes.content}>{data?.description}</p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          {data?.previewRequirements?.length && (
            <Fragment>
              <div className={classes.requirementTable}>
                {data?.previewRequirements?.map((item, index) => (
                  <RequirementRow item={item} key={index} />
                ))}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

Requirements.propTypes = propTypes;
export default Requirements;
