import React, { Fragment, useContext, useEffect } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  Loader,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { EvaluationStateContext } from "../../../context";
import { getTemplateData } from "../../../context/actions/templateActions";
import styles from "./styles";
import { TEMPLATE_SECTION_OVERVIEW } from "../../../lib/constants";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

const propTypes = { templateId: PropType.number, selectedMenu: PropType.bool };
const useStyles = makeStyles(() => styles);
const TemplateOverview = ({ templateId, selectedMenu }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, dispatch] = useContext(EvaluationStateContext);
  const { isLoading, data } = state?.template?.overview || {};

  useEffect(() => {
    if (templateId) {
      getTemplateData(dispatch, { templateId, section: TEMPLATE_SECTION_OVERVIEW });
    }
  }, [dispatch, templateId]);

  return (
    <Fragment>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            rootClassName={classNames(selectedMenu && "activeHeader")}
            styling={"h4"}
            color={"yam"}
            boldness={"medium"}
          >
            {data?.name}
            {t(" Template Overview")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <h4 className={classNames(classes.header, selectedMenu && "activeHeader")}>
            {data?.name}
            {t(" Template Overview")}
          </h4>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>

      {isLoading && (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      )}
      {!isLoading && (
        <Fragment>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <div className={classes.contentGCOM3606}>
                <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
                  {data?.description}
                </TypographyComponent>
              </div>
              <div className={classes.contentGCOM3606}>
                <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
                  {t("The template provides information such as")}
                </TypographyComponent>
                <TypographyComponent element={"span"} styling={"p2"} color={"black"} boldness={"medium"}>{` ${t(
                  "detailed requirements, vendors to consider"
                )}`}</TypographyComponent>
                <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
                  {t(", a")}
                </TypographyComponent>
                <TypographyComponent element={"span"} styling={"p2"} color={"black"} boldness={"medium"}>{` ${t(
                  "comprehensive checklist"
                )}`}</TypographyComponent>
                <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>{` ${t(
                  "and a"
                )}`}</TypographyComponent>
                <TypographyComponent element={"span"} styling={"p2"} color={"black"} boldness={"medium"}>{` ${t(
                  "scorecard"
                )}`}</TypographyComponent>
                <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
                  {` ${t(
                    "so that you can have confidence in your final decision. Use this template to manage the evaluation of different"
                  )} `}
                </TypographyComponent>
                <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
                  {data?.name || ""}
                </TypographyComponent>
                <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>{` ${t(
                  "platforms."
                )}`}</TypographyComponent>
              </div>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <div className={classes.content}>{data?.description}</div>
              <div className={classes.content}>
                <span>{t("The template provides information such as")}</span>
                <span className={classes.details}>{` ${t("detailed requirements, vendors to consider")}`}</span>
                <span>{t(", a")}</span>
                <span className={classes.details}>{` ${t("comprehensive checklist")}`}</span>
                <span>{` ${t("and a")}`}</span>
                <span className={classes.details}>{` ${t("scorecard")}`}</span>
                <span>
                  {` ${t(
                    "so that you can have confidence in your final decision. Use this template to manage the evaluation of different"
                  )} `}
                </span>
                <span>{data?.name || ""}</span>
                <span>{` ${t("platforms.")}`}</span>
              </div>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </Fragment>
      )}
    </Fragment>
  );
};

TemplateOverview.propTypes = propTypes;
export default TemplateOverview;
