import { colorPalette } from "gx-npm-common-styles";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    gap: "24px",
    paddingTop: "12px",
  },
  contentHeader: {
    color: colorPalette.neutrals.carbon.hex,
    width: "300px",
  },
  contentHeaderGCOM3606: {
    width: "300px",
  },
  contentText: {
    paddingTop: "4px",
    color: colorPalette.neutrals.iron.hex,
    paddingBottom: "32px",
  },
  contentTextGCOM3606: {
    paddingTop: "4px",
    paddingBottom: "32px",
  },
  leftContainer: {
    width: "363px",
  },
};

export default styles;
