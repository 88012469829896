const errorBannerStyle = {
  container: {
    display: "inline-flex",
    "& svg": {
      minHeight: 24,
      minWidth: 24,
    },
  },
  banner: {
    marginLeft: 10,
  },
};

export default errorBannerStyle;
