import * as singleSpa from "single-spa";
import { getRequest, postRequest } from "./apiRequests";
import { templateTypes } from "./actionTypes";
import { getApiUrl, processGetResponse } from "./actionUtils";
import { reportFullStoryTemplateCreateEvent } from "./reporting-template-creation.lib";

const getTemplateData = async (dispatch, data, apiVersion) => {
  const section = data?.section;
  dispatch({ type: templateTypes.START_SECTION_LOADER, section });
  const url = getApiUrl(data?.templateId, section, apiVersion);
  const response = await getRequest(url);
  const payload = processGetResponse(response);
  dispatch({ ...payload, section, type: templateTypes.GET_SECTION_DATA });
};

const setTemplateCategory = async (dispatch, data) => {
  const category = data?.category;
  dispatch({ category, type: templateTypes.SET_TEMPLATE_CATEGORY });
};

const createInitiativeFromTemplateV2 = (dispatch, data) => {
  async function createInitiative() {
    const type = templateTypes.CREATE_INITIATIVE_V2;
    dispatch({ error: null, isLoading: true, type });
    let error;
    const { templateId } = data;
    const url = "/api/v2/initiatives/";
    const sessionPriorityIds = sessionStorage.getItem(`selectedPriorities-${templateId}`);
    const selectedPriorityIds = sessionPriorityIds ? JSON.parse(sessionPriorityIds) : [];
    const payload = { selectedPriorityIds, templateId };
    const response = await postRequest(url, payload);
    if (response?.status === 201 && response.data?.data?.initiativeId) {
      localStorage.setItem("showRibbonAnimation", "true");
      sessionStorage.removeItem(`selectedPriorities-${templateId}`);
      reportFullStoryTemplateCreateEvent(data);
      singleSpa.navigateToUrl(`/s/evaluation/${response.data.data.initiativeId}/overview`);
    } else {
      error = response;
    }
    dispatch({ error, type, isLoading: false });
  }
  return createInitiative();
};
const createInitiativeFromTemplateV3 = (dispatch, data) => {
  async function createInitiative() {
    const type = templateTypes.CREATE_INITIATIVE_V3;
    dispatch({ error: null, isLoading: true, type });
    let error;
    const { templateId } = data;
    const url = "/api/v3/initiatives/";
    const weightedPriorities = JSON.parse(sessionStorage.getItem(`weightedPriorities-${templateId}`) || "[]");
    const payload = { templateId, weightedPriorities };
    const response = await postRequest(url, payload);
    if (response?.status === 201 && response.data?.data?.initiativeId) {
      localStorage.setItem("showRibbonAnimation", "true");
      sessionStorage.removeItem(`weightedPriorities-${templateId}`);
      reportFullStoryTemplateCreateEvent(data);
      singleSpa.navigateToUrl(`/s/evaluation/${response.data.data.initiativeId}/overview`);
    } else {
      error = response;
    }
    dispatch({ error, type, isLoading: false });
  }
  return createInitiative();
};

export { createInitiativeFromTemplateV2, createInitiativeFromTemplateV3, getTemplateData, setTemplateCategory };
