import React from "react";
import { makeStyles } from "@material-ui/core";
import * as singleSpa from "single-spa";
import { updateStateSubject } from "gx-npm-messenger-util";
import {
  ProductLogo,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./product-list-item.styles";
import PropTypes from "prop-types";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const useStyles = makeStyles(() => styles);

const propTypes = {
  categoryId: PropTypes.number,
  categoryName: PropTypes.string,
  isSearchData: PropTypes.bool,
  item: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    imageLoc: PropTypes.string,
  }),
  searchResultsCount: PropTypes.number,
  searchTerm: PropTypes.string,
  templateId: PropTypes.number,
};

const ProductListItem = ({
  categoryId,
  categoryName,
  isSearchData,
  item,
  searchResultsCount,
  searchTerm,
  templateId,
}) => {
  const handleProductClick = () => {
    singleSpa.navigateToUrl(`/s/market/${templateId}/product/${item.id}`);
    updateStateSubject("PRODUCT_PREVIEW_FULLSTORY", {
      category: categoryName,
      categoryId,
      isSearchData,
      searchResultsCount,
      searchTerm,
    });
  };
  const classes = useStyles();
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      aria-label={"product-button"}
      className={classes.container}
      onClick={handleProductClick}
      role="button"
      tabIndex={0}
    >
      <div className={classes.imageContainer}>
        <ProductLogo
          name={item?.name}
          logo={item?.imageLoc}
          imageWidth={"100%"}
          imageHeight={"100%"}
          fontSize={"42px"}
          addedByUser={false}
          svgStyle={{ height: "69px", width: "69px" }}
        />
      </div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            rootClassName={classes.productNameGCOM3606}
            styling={"p4"}
            color={"coal"}
            boldness={"medium"}
          >
            {item.name}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <div className={classes.productName}>{item.name}</div>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </div>
  );
};
ProductListItem.propTypes = propTypes;
export { ProductListItem };
