import React, { createContext, useReducer } from "react";
import { templateReducer } from "./reducers/templateReducer";

const EvaluationStateContext = createContext();

const combineReducers = (reducers) => {
  return (state, action) => {
    return Object.keys(reducers).reduce((acc, prop) => {
      return {
        ...acc,
        ...reducers[prop]({ [prop]: acc[prop] }, action),
      };
    }, state);
  };
};

const rootReducer = combineReducers({
  template: templateReducer,
});

const EvaluationStateProvider = (props) => {
  const initialState = {
    template: {
      overview: {
        isLoading: false,
        error: null,
        data: "",
      },
      requirements: {
        isLoading: false,
        error: null,
        data: "",
      },
      products: {
        isLoading: false,
        error: null,
        data: "",
      },
      checklist: {
        isLoading: false,
        error: null,
        data: "",
      },
      scorecard: {
        isLoading: false,
        error: null,
        data: "",
      },
      createInitiative: {
        isLoading: false,
        error: null,
      },
      category: null,
    },
  };
  const [state, dispatch] = useReducer(rootReducer, initialState);

  return (
    <EvaluationStateContext.Provider value={[state, dispatch]}>
      {props.children}
    </EvaluationStateContext.Provider>
  );
};

export { EvaluationStateContext, EvaluationStateProvider };
