import { colorPalette, weightPalette } from "gx-npm-common-styles";

const styles = {
  loaderContainer: {
    minHeight: "200px",
    position: "relative",
  },
  header: {
    fontWeight: weightPalette.semiBold.amount,
    fontVariationSettings: weightPalette.semiBold.variation,
    fontSize: "24px",
    lineHeight: "31px",
    letterSpacing: "0.15px",
    color: colorPalette.neutrals.carbon.hex,
  },
  content: {
    marginTop: "12px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: colorPalette.neutrals.coal.hex,
    maxWidth: "600px",
  },
  contentGCOM3606: {
    marginTop: "12px",
    maxWidth: "600px",
  },
  productContainer: {
    display: "flex",
    marginTop: "32px",
    flexWrap: "wrap",
    minWidth: "550px",
  },
  countBtn: {
    marginTop: "24px",
  },
  noData: {
    paddingTop: "12px",
  },
};

export default styles;
