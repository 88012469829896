import { colorPalette } from "gx-npm-common-styles";

const styles = {
  container: {
    display: "flex",
    position: "relative",
  },
  menu: {
    marginLeft: "56px",
    marginTop: 56,
    position: "relative",
    width: "220px",
    "@media (min-width: 1440px)": {
      width: "328px",
    },
  },
  content: {
    height: "calc(100vh - 130px)",
    overflowY: "scroll",
    padding: "0 56px 0 12px",
    width: "calc(100% - 276px)",
    "@media (min-width: 1440px)": {
      padding: "0 228px 0 12px",
      width: "calc(100% - 316px)",
    },
  },
  detailsContainer: {
    marginBottom: "64px",
    maxWidth: "748px",
    marginTop: "auto",
  },
  templateContainer: {
    position: "sticky",
    top: "0px",
    zIndex: "1001",
  },
  detailItem: {
    marginTop: 56,
    "& .activeHeader": {
      color: colorPalette.brand.yam.hex,
    },
  },
  detailItemFirstElement: {
    marginTop: 32,
  },
};

export default styles;
