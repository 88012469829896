import { colorPalette, weightPalette } from "gx-npm-common-styles";

const styles = {
  rowContainer: {
    alignItems: "center",
    border: `1px solid ${colorPalette.neutrals.silver.hex}`,
    display: "flex",
    justifyContent: "space-between",
  },
  requirementDetails: {
    margin: "20px 20px 24px 32px",
  },
  requirementHeader: {
    color: colorPalette.neutrals.carbon.hex,
    fontWeight: weightPalette.semiBold.amount,
    fontVariationSettings: weightPalette.semiBold.variation,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
  },
  requirementDescription: {
    marginTop: "5px",
    color: colorPalette.neutrals.coal.hex,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
  },
  requirementsPriority: {
    minWidth: "109px",
    marginRight: "24px",
    height: "41px",
    background: colorPalette.neutrals.quartz.hex,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  priority: {
    fontSize: "14px",
    lineHeight: "21px",
    letterSpacing: "0.25px",
    fontWeight: weightPalette.medium.amount,
    fontVariationSettings: weightPalette.medium.variation,
    color: colorPalette.neutrals.carbon.hex,
  },
};
export default styles;
