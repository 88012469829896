const styles = {
  paper: {
    width: "calc(100% - 76px)",
  },
  root: {
    "& .template-preview-app-MuiDrawer-paper": {
      maxWidth: "unset",
    },
    "& .gx-slidein-body": {
      padding: 0,
    },
  },
  container: {
    minWidth: "948px",
    overflow: "hidden",
  },
};

export default styles;
