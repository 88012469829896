import { createGenerateClassName, createMuiTheme, StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import { commonTheme } from "gx-npm-common-styles";
import { messengerFlagLoader, useUserState } from "gx-npm-lib";
import { createStateSubscription, destroyStateSubscription, updateStateSubject } from "gx-npm-messenger-util";
import { FeatureFlagProvider } from "gx-npm-ui";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { EvaluationStateProvider } from "./context";
import { TemplateDetails } from "./types";
import App from "./app.component";

const stateNameTemplate = "TEMPLATE_PREVIEW";
const generateClassName = createGenerateClassName({ seed: "template-preview-app" });
const theme = createMuiTheme(commonTheme);
type RootProps = { drawerStatus?: boolean };
export default function Root({ drawerStatus = false }: RootProps) {
  const [isDrawer, setIsDrawer] = useState(drawerStatus);
  const [templateDetails, setTemplateDetails] = useState<TemplateDetails>({} as TemplateDetails);
  const { hasUserState, isEntitled } = useUserState();

  useEffect(() => {
    const subscriptionTemplate = createStateSubscription(stateNameTemplate, handleStateUpdate);
    return () => {
      updateStateSubject(stateNameTemplate, { display: false, isDialog: true });
      destroyStateSubscription(subscriptionTemplate);
    };
  }, []);

  const handleStateUpdate = (updatedState: TemplateDetails & { display: boolean }) => {
    if (updatedState) {
      setIsDrawer(updatedState.display);
      setTemplateDetails(updatedState);
    }
  };

  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <EvaluationStateProvider>
        <ThemeProvider theme={theme}>
          <StylesProvider generateClassName={generateClassName}>
            <BrowserRouter>
              <Routes>
                <Route
                  path={`/s/market/:marketId/preview`}
                  element={
                    <App
                      isDrawer={isDrawer}
                      isEntitled={hasUserState && isEntitled}
                      templateDetails={templateDetails}
                    />
                  }
                />
                <Route
                  path={`/s/market/:marketId/product/:productId/preview`}
                  element={
                    <App
                      isDrawer={isDrawer}
                      isEntitled={hasUserState && isEntitled}
                      templateDetails={templateDetails}
                    />
                  }
                />
              </Routes>
            </BrowserRouter>
          </StylesProvider>
        </ThemeProvider>
      </EvaluationStateProvider>
    </FeatureFlagProvider>
  );
}
